var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"t1FuqoDTJDkZMOqiTCZIW"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/next-assets";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_DSN,
    sampleRate: 0.75, // this is sent 75% of error
    tracesSampleRate: 0.05, // this will sent 5% of transcation
  })
}
